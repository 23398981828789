<template>
    <div class="news-list">
        <h1 class="title-h1">
            {{ i18n ('News') }}
        </h1>
        <div class="news-item" v-for="item in news" :key="item.id">
            <a @click="$router.push('/news/' + item.id)">
                <div class="card">
                    <div class="card-content">
                        <time class="date">{{ formatDate(item.created_at) }}</time>
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4 title-news">{{ item.name }}</p>
                            </div>
                        </div>
                        <div class="content">
                            {{ item.program }}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import i18n from '@enso-ui/ui/src/modules/plugins/i18n';

export default {
    name: 'ListNews',
    inject: ['http', 'route', 'http'],
    data() {
        return {
            news: [],
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        i18n,
        fetch() {
            this.http
                .get(this.route('news.listNews'))
                .then(({ data }) => {
                    this.news = data.news;
                })
                .catch(this.errorHandler);
        },
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString('es-ES', options);
        },
        goToNewsDetail(id) {
            this.$router.push(id);
        },
    },
};

</script>

<style scoped lang="scss">
.news-list {
    padding: 20px;
}

.news-item {
    margin-bottom: 20px;
}

.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.title-news {
    font-weight: bold;
    margin: 0;
}

.date {
    color: #666;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: underline;
}
</style>
